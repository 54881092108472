import { graphql, PageProps } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import SEO from '@/components/seo';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import UIBox from '@/components/UI/UIBox';
import hoverCircle from '@/components/Interaction/hoverCircle';
import { Context } from '@/components/Layout';

import * as titleLikeList from '@/styles/object/title-like-list.module.scss';
import * as textLead from '@/styles/object/text-lead.module.scss';
import * as pageHeader from '@/styles/object/page-header.module.scss';

const ServiceCategoryDigitalMarketing: React.FC<
  PageProps<GatsbyTypes.ServiceCategoryDigitalMarketingQuery>
> = ({ data }) => {
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);

  const ConsultingArray: Pick<
    GatsbyTypes.MicrocmsService,
    'id' | 'serviceId' | 'title' | 'category' | 'label' | 'description' | 'displayIndex'
  >[] = [];
  const PlatformArray: Pick<
    GatsbyTypes.MicrocmsService,
    'id' | 'serviceId' | 'title' | 'category' | 'label' | 'description' | 'displayIndex'
  >[] = [];
  const ExecutionSupportArray: Pick<
    GatsbyTypes.MicrocmsService,
    'id' | 'serviceId' | 'title' | 'category' | 'label' | 'description' | 'displayIndex'
  >[] = [];
  const OtherArray: Pick<
    GatsbyTypes.MicrocmsService,
    'id' | 'serviceId' | 'title' | 'category' | 'label' | 'description' | 'displayIndex'
  >[] = [];

  data.allMicrocmsService.edges.forEach(({ node }) => {
    if (node.label[0] === `DM/コンサルティングサービス`) {
      ConsultingArray.push(node);
    } else if (node.label[0] === `DM/プラットフォーム構築`) {
      PlatformArray.push(node);
    } else if (node.label[0] === `DM/実行支援`) {
      ExecutionSupportArray.push(node);
    } else {
      OtherArray.push(node);
    }
  });

  return (
    !loading && (
      <>
        <SEO
          title={`${data.microcmsService.title} | サービス`}
          description={data.microcmsService.description}
        />
        <div className="page-wrap --digital-marketing">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeader.page_header}>
            <h1 className={pageHeader.page_header__title}>
              <small className={pageHeader.page_header__head_text}>
                {data.microcmsService.title}
              </small>
              <div className={pageHeader.page_header__text}>
                {data.microcmsService.subTitle}
              </div>
            </h1>
          </header>
          <div className="page-contents-box">
            <div className="section">
              <p
                className={textLead.text_lead}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: data.microcmsService.description,
                }}
              />
            </div>
            <div className="section">
              <section className="section-inner">
                <h3 className={titleLikeList.title_like_list}>コンサルティングサービス</h3>
                <div className="box-2rows">
                  {ConsultingArray.map(
                    ({ serviceId, category, title, description, displayIndex }) => (
                      <UIBox
                        key={serviceId}
                        title={title}
                        description={description}
                        link={`/service/${category}/${serviceId}/`}
                        displayIndex={displayIndex}
                      />
                    ),
                  )}
                </div>
              </section>
              <section className="section-inner">
                <h3 className={titleLikeList.title_like_list}>
                  プラットフォーム構築
                </h3>
                <div className="box-2rows">
                  {PlatformArray.map(
                    ({ serviceId, category, title, description, displayIndex }) => (
                      <UIBox
                        key={serviceId}
                        title={title}
                        description={description}
                        link={`/service/${category}/${serviceId}/`}
                        displayIndex={displayIndex}
                      />
                    ),
                  )}
                </div>
              </section>
              <section className="section-inner">
                <h3 className={titleLikeList.title_like_list}>実行支援</h3>
                <div className="box-2rows">
                  {ExecutionSupportArray.map(
                    ({ serviceId, category, title, description, displayIndex }) => (
                      <UIBox
                        key={serviceId}
                        title={title}
                        description={description}
                        link={`/service/${category}/${serviceId}/`}
                        displayIndex={displayIndex}
                      />
                    ),
                  )}
                </div>
              </section>
              {OtherArray.length > 0 && (
                <>
                  <section className="section-inner">
                    <h3 className={titleLikeList.title_like_list}>その他</h3>
                    <div className="box-2rows">
                      {OtherArray.map(
                        ({ serviceId, category, title, description, displayIndex }) => (
                          <UIBox
                            key={serviceId}
                            title={title}
                            description={description}
                            link={`/service/${category}/${serviceId}/`}
                            displayIndex={displayIndex}
                          />
                        ),
                      )}
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
        <UIBreadcrumb
          UpperItem={[[`Service`, `/service`]]}
          CurrentItem="Digital Marketing"
        />
      </>
    )
  );
};

export const query = graphql`
  query ServiceCategoryDigitalMarketing {
    allMicrocmsService(
      filter: {
        category: { in: "digital-marketing" }
        serviceId: { ne: "digital-marketing" }
      }
      sort: { fields: sort }
    ) {
      edges {
        node {
          id
          serviceId
          title
          category
          label
          description
          displayIndex
        }
      }
    }
    microcmsService(serviceId: { eq: "digital-marketing" }) {
      id
      title
      subTitle
      description
    }
  }
`;

export default ServiceCategoryDigitalMarketing;
